import React, { lazy, Suspense, useContext, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    SttButton,
    SttLoading,
    SttExpansionPanel,
    SttCircularProgress,
    SttDivider,
    SttTranslateHook,
    SttHeading,
    SttContainer,
} from '@stt-componentes/core';
import { Formik } from 'formik';
import HttpStatus from 'http-status-codes';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import alerta from '../../../signals/alerta';
import { useNavigate } from "react-router-dom";
import { INITIAL_VALUES_AGENDAMENTO } from "./initial-values";
import { ASSUNTO, CATEGORIA, DATA_HORA_INICIO, PARTICIPANTES, REDE } from "./field-names";
import validationSchema from "./validation-schema";
import { agendamentoAlteracao, pesquisar, semRede } from "../../../signals/agendamento";
import { batch } from '@preact/signals-react';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        marginTop: theme.spacing(2)
    },
    headerButton: {
        marginTop: theme.spacing(2)
    },
    notificacao: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginBottom: theme.spacing(1)
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    botaoVoltar: {
        marginTop: theme.spacing(2)
    }
}));

const Geral = lazy(() => import('./geral'));
const Participantes = lazy(() => import('./participantes'));

const FormCadastroAgendamento = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const classes = useStyles();
    const navigate = useNavigate();
    useSignals();

    const secoesAbertas = useSignal({
        'geral': true,
        'participantes': false,
    });
    const secaoParticipantes = useRef(null);
    const secaoGeral = useRef(null);

    useEffect(() => {
        return () => {
            agendamentoAlteracao.value = null
        }
    }, [])

    const sucessoSalvar = () => {
        batch(() => {
            alerta.value = {
                ...alerta.value,
                open: false
            }
            pesquisar.value = true;
        });
        navigate('/inicio');
    }

    const erroSalvar = () => {
        alerta.value = {
            ...alerta.value,
            open: false
        }
    }

    const enviarForm = (dados, setSubmitting) => {
        setSubmitting(true);

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${global.gConfig.url_base_conecta}/agendamento`, dados, { headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = strings.sucessoSalvarAgendamento;
                options = [{
                    title: strings.ok,
                    onClick: sucessoSalvar
                }];
                onClose = sucessoSalvar
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if (response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: erroSalvar
                }];
                onClose = erroSalvar
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    const abrirSecao = (secao, abrir) => {
        let novoSecoesAbertas = {
            ...secoesAbertas.value
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = abrir;
        secoesAbertas.value = novoSecoesAbertas;
    }

    const verificarSecoesComErro = (validateForm) => {
        validateForm().then((retorno) => {
            let node = null;

            if (retorno[ASSUNTO] || retorno[REDE] || retorno[CATEGORIA] || retorno[DATA_HORA_INICIO]) {
                abrirSecao('geral', true);
                node = secaoGeral.current;
            } else if (retorno[PARTICIPANTES]) {
                abrirSecao('participantes', true);
                node = secaoParticipantes.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 200);
            }
        });
    }

    return (
        <SttContainer>
            <Formik
                initialValues={agendamentoAlteracao.value || INITIAL_VALUES_AGENDAMENTO}
                validationSchema={schema}
                validateOnChange={false}
                onSubmit={(dados, { setSubmitting }) => {
                    setSubmitting(false);
                    alerta.value = {
                        ...alerta.value,
                        open: true,
                        title: strings.atencao,
                        type: 'alert',
                        message: dados._id ? strings.confirmarAlterarAgendamento : strings.confirmarSalvarAgendamento,
                        options: [{
                            title: strings.sim,
                            onClick: () => {
                                enviarForm(dados, setSubmitting)
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        },
                        {
                            title: strings.nao,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                };
                            }
                        }],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            };
                        }
                    };
                }}
            >
                {
                    ({
                        isSubmitting,
                        values,
                        handleSubmit,
                        validateForm
                    }) => {
                        return (
                            <form onSubmit={handleSubmit} noValidate>
                                <SttButton
                                    type="button"
                                    variant="outlined"
                                    color="primary"
                                    className={classes.botaoVoltar}
                                    nomarginleft="true"
                                    onClick={() => {
                                        alerta.value = {
                                            ...alerta.value,
                                            open: true,
                                            title: strings.atencao,
                                            type: 'alert',
                                            message: strings.retornarTelaPesquisa,
                                            options: [{
                                                title: strings.sim,
                                                onClick: () => {
                                                    navigate('/inicio');
                                                    alerta.value = {
                                                        ...alerta.value,
                                                        open: false
                                                    };
                                                }
                                            },
                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    alerta.value = {
                                                        ...alerta.value,
                                                        open: false
                                                    };
                                                }
                                            }],
                                            onClose: () => {
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        };
                                    }}
                                >
                                    {strings.voltar}
                                </SttButton>

                                <SttHeading variant="h1" color="primary" align="center" className={classes.header}>{strings.agendamento}</SttHeading>

                                <SttExpansionPanel
                                    classegriditem={classes.expansionPanel}
                                    opened={secoesAbertas.value['geral']}
                                    title={strings.dadosGerais}
                                    callback={estadoInterno => abrirSecao('geral', estadoInterno)}
                                    children={
                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                            <div ref={secaoGeral}></div>
                                            <Geral />
                                        </Suspense>
                                    }
                                />
                                <SttDivider />

                                {
                                    values[REDE] &&
                                    <>
                                        <SttExpansionPanel
                                            classegriditem={classes.expansionPanel}
                                            opened={secoesAbertas.value['participantes']}
                                            title={strings.participantes}
                                            callback={estadoInterno => abrirSecao('participantes', estadoInterno)}
                                            children={
                                                <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                    <div ref={secaoParticipantes}></div>
                                                    <Participantes />
                                                </Suspense>
                                            }
                                        />
                                        <SttDivider />
                                    </>
                                }

                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoMensagemEspera}
                                />
                                {
                                    !semRede.value &&
                                    <div className={classes.buttonWrapper}>
                                        <SttButton
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            nomarginleft="true"
                                            className={classes.button}
                                            onClick={() => verificarSecoesComErro(validateForm)}
                                            disabled={isSubmitting}
                                        >
                                            {strings.salvar}
                                        </SttButton>
                                    </div>
                                }
                            </form>
                        )
                    }
                }
            </Formik>
        </SttContainer >
    );
}

export default FormCadastroAgendamento;