import React, { useEffect, lazy, Suspense, memo, useContext } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const RedeSuspense = memo((props) => {
    const Rede = lazy(() => import('../../componentes/administrativo/rede/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Rede {...props} />
        </Suspense>
    )
});

const CategoriasSuspense = memo((props) => {
    const Categoria = lazy(() => import('../../componentes/administrativo/categoria/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <Categoria {...props} />
        </Suspense>
    )
});

const MotivoCancelamentoSuspense = memo((props) => {
    const MotivoCancelamento = lazy(() => import('../../componentes/administrativo/motivo-cancelamento/pesquisa'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <MotivoCancelamento {...props} />
        </Suspense>
    )
});

const IndexAdministrativo = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useEffect(() => {
        abas.value = [
            {
                titulo: strings.redes,
                conteudo: RedeSuspense,
                key: 'RedeSuspense',
                permanente: true
            },
            {
                titulo: strings.categorias,
                conteudo: CategoriasSuspense,
                key: 'CategoriasSuspense',
                permanente: true
            },
            {
                titulo: strings.motivoCancelamento,
                conteudo: MotivoCancelamentoSuspense,
                key: 'MotivoCancelamentoSuspense',
                permanente: true
            }
        ];
    }, []);

    return (
        <SttTabs abas={abas.value}
            canClose={true}
            className={classes.wrapper}
            abaAtiva={abaAtiva.value}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(IndexAdministrativo);