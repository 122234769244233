import React, { useContext } from "react";
import {
    SttBox,
    SttHeading,
    SttHidden,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import {
    atendimentoEncerrado,
    emChamadaVideo,
    idSalaAtendimento,
    registroAberto,
} from "../../signals/eventos";
import ChatFormContainer from './chat-form';
import {
    CameraDeVideoDesligadaIcon,
    CameraDeVideoLigadaIcon,
    DesligarIcon,
    RegistroIcon
} from '@stt-componentes/icons';
import ModalRegistroEvento from "./registro";
import { agendamentoEvento } from "../../signals/eventos";
import MensagensContainer from "./mensagens";
import { EVENTOS_SOCKET } from "../../common/Constants";
import socket from "../../signals/socket";
import alerta from "../../signals/alerta";
import usuario from "../../signals/usuario";

const useStyles = makeStyles(theme => ({
    heading: {
        marginLeft: theme.spacing(2)
    },
    headerBox: {
        height: '63px',
        padding: "0px 15px",
        borderBottom: '1px solid #D9D9D9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center"
    },
    iconButton: {
        marginLeft: theme.spacing(1.5),
    },
}));

const Janela = ({ eventoAberto }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const inserirRegistro = useSignal(false);

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.STATUS_REGISTRO_ABERTO, (dados) => {
                dados = JSON.parse(dados);
                registroAberto.value = dados.registroAberto;
            });
        }
    })

    return (
        <>
            {/* Container com o header do painel */}
            <SttBox className={classes.headerBox}>
                {/* Container com o título/subtítulo do cabeçalho */}
                <SttBox
                    display="flex"
                    flexDirection="column"
                    pl="4px"
                    alignItems="flex-start"
                >
                    <SttBox display="flex" justifyContent="space-between" width="100%">
                        <SttHeading variant="h2" color="primary">{agendamentoEvento.value.assunto}</SttHeading>
                    </SttBox>
                </SttBox>
                {/* Container com os botões de ação */}
                {
                    !agendamentoEvento.value.finalizado &&
                    <SttBox display="flex" alignItems="flex-end">
                        <SttHidden smUp>
                            {
                                emChamadaVideo.value && eventoAberto?.value &&
                                <Tooltip title={strings.abrirJanelaVideo}>
                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                        onClick={() => {
                                            eventoAberto.value = false;
                                        }}>
                                        <CameraDeVideoLigadaIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </SttHidden>
                        {
                            agendamentoEvento.value.categoria.permiteRegistro &&
                            <Tooltip title={strings.registroAtendimento}>
                                <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                    disabled={registroAberto.value}
                                    onClick={() => {
                                        if (!atendimentoEncerrado.value) {
                                            socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_REGISTRO_ABERTO, JSON.stringify({
                                                idFuncionario: usuario.value.idFuncionario,
                                                registroAberto: true
                                            }));
                                        }
                                        inserirRegistro.value = true;
                                    }}>
                                    <RegistroIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            !atendimentoEncerrado.value &&
                            <>
                                {
                                    !emChamadaVideo.value ?
                                        <Tooltip title={strings.chamadaVideo}>
                                            <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                                onClick={() => {
                                                    socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, JSON.stringify({
                                                        idSala: idSalaAtendimento.value,
                                                        videoAtivo: true
                                                    }));
                                                }}>
                                                <CameraDeVideoLigadaIcon />
                                            </IconButton>
                                        </Tooltip> :
                                        <Tooltip title={strings.fecharChamadaVideo}>
                                            <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                                onClick={() => {
                                                    alerta.value = {
                                                        ...alerta.value,
                                                        open: true,
                                                        title: strings.atencao,
                                                        type: 'alert',
                                                        message: strings.confirmarFecharVideo,
                                                        options: [{
                                                            title: strings.sim,
                                                            onClick: () => {
                                                                socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_ATENDIMENTO_VIDEO, JSON.stringify({
                                                                    idSala: idSalaAtendimento.value,
                                                                    videoAtivo: false
                                                                }));
                                                                alerta.value = {
                                                                    ...alerta.value,
                                                                    open: false
                                                                };
                                                            }
                                                        },
                                                        {
                                                            title: strings.nao,
                                                            onClick: () => {
                                                                alerta.value = {
                                                                    ...alerta.value,
                                                                    open: false
                                                                };
                                                            }
                                                        }],
                                                        onClose: () => {
                                                            alerta.value = {
                                                                ...alerta.value,
                                                                open: false
                                                            };
                                                        }
                                                    };
                                                }}>
                                                <CameraDeVideoDesligadaIcon />
                                            </IconButton>
                                        </Tooltip>
                                }
                                <Tooltip title={strings.finalizarAtendimento}>
                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                        onClick={() => {
                                            alerta.value = {
                                                ...alerta.value,
                                                open: true,
                                                title: strings.atencao,
                                                type: 'alert',
                                                message: strings.confirmarFinalizarEvento,
                                                options: [{
                                                    title: strings.sim,
                                                    onClick: () => {
                                                        socket.value.emit(
                                                            EVENTOS_SOCKET.FINALIZAR_ATENDIMENTO,
                                                            JSON.stringify({
                                                                dadosUsuario: {
                                                                    cpf: usuario.value.cpf,
                                                                    nome: usuario.value.nome,
                                                                    id: usuario.value.idFuncionario,
                                                                }
                                                            }));
                                                        alerta.value = {
                                                            ...alerta.value,
                                                            open: false
                                                        };
                                                    }
                                                },
                                                {
                                                    title: strings.nao,
                                                    onClick: () => {
                                                        alerta.value = {
                                                            ...alerta.value,
                                                            open: false
                                                        };
                                                    }
                                                }],
                                                onClose: () => {
                                                    alerta.value = {
                                                        ...alerta.value,
                                                        open: false
                                                    };
                                                }
                                            };

                                        }}>
                                        <DesligarIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                    </SttBox>
                }
            </SttBox>
            {/* Container de mensagens enviadas */}
            <SttBox flex={1} minHeight={0} position="relative">
                <SttBox
                    height="100%"
                    width="100%"
                    alt="background"
                />
                <MensagensContainer />
            </SttBox>
            {
                !atendimentoEncerrado.value &&
                <ChatFormContainer />
            }
            {
                inserirRegistro.value &&
                <ModalRegistroEvento inserirRegistro={inserirRegistro} />
            }
        </>
    );
}

export default Janela;