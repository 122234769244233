import React, { lazy, Suspense, useContext, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    SttModal,
    SttButton,
    SttTranslateHook,
    SttExpansionPanel,
    SttCircularProgress,
    SttLoading,
    SttDivider
} from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import {
    Formik
} from 'formik';
import * as yup from 'yup';
import alerta from '../../../signals/alerta';
import { atendimentoEncerrado, idEvento, registroEvento } from '../../../signals/eventos';
import { getHeaders } from '../../../request';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { EVENTOS_SOCKET } from '../../../common/Constants';
import usuario from '../../../signals/usuario';
import socket from '../../../signals/socket';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        overflow: 'hidden'
    },
    buttonWrapper: {
        marginTop: theme.spacing(1)
    },
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    expansionPanel: {
        width: '100%'
    }
}));

const validationSchema = (strings) => {
    return yup.object().shape({
        // 'relatoCaso': yup
        //     .string()
        //     .trim()
        //     .nullable()
    });
}

const initialValuesForm = {
    paciente: {},
    relatoCaso: '',
    duvidaPrincipal: '',
    historicoSaude: '',
    tratamentosAndamento: '',
    complementacoes: '',
    resultadosExames: '',
    desfechoAtendimento: '',
    cid10: [],
    ciap2: [],
}

const RelatoPaciente = lazy(() => import('./relato-paciente'));
const Desfecho = lazy(() => import('./desfecho'));
const Paciente = lazy(() => import('./paciente'));
const SttCid10 = lazy(() => import('@stt-componentes/cid10'));
const SttCiap2 = lazy(() => import('@stt-componentes/ciap2'));

const ModalRegistroEvento = ({ inserirRegistro }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    useSignals();

    const formRef = useRef();

    const initialValues = useSignal(null);

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_conecta}/evento/registro`, { params: { idEvento: idEvento.value }, headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (data.data.registro) {
                    if (!data.data.registro.paciente) {
                        data.data.registro.paciente = {}
                    }
                    initialValues.value = data.data.registro;
                    return;
                }
                initialValues.value = { ...initialValuesForm };
            })
            .catch(err => {
                console.log(err)
                initialValues.value = { ...initialValuesForm };
            });
    }, [])

    useSignals();

    const fecharModalRegistro = () => {
        if (!atendimentoEncerrado.value) {
            socket.value.emit(EVENTOS_SOCKET.ALTERAR_STATUS_REGISTRO_ABERTO, JSON.stringify({
                idFuncionario: usuario.value.idFuncionario,
                registroAberto: false
            }));
        }
        inserirRegistro.value = false;
    }

    const enviarForm = (dados, setSubmitting) => {
        setSubmitting(true);

        const dadosSalvar = { ...dados };
        dadosSalvar.idEvento = idEvento.value;

        let tipoAlerta = '';
        let tituloAlerta = '';
        let mensagemAlerta = '';
        let options = [];
        let onClose = () => { };

        axios.post(`${global.gConfig.url_base_conecta}/registro`, dadosSalvar, { headers: getHeaders() })
            .then((response) => {
                tipoAlerta = 'success';
                tituloAlerta = strings.sucesso;
                mensagemAlerta = strings.registroAtendimentoSalvoSucesso;

                dadosSalvar._id = response.data.idRegistro;
                registroEvento.value = dadosSalvar;

                const func = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    }
                    fecharModalRegistro();
                }
                options = [{
                    title: strings.ok,
                    onClick: func
                }];
                onClose = func;
            })
            .catch(err => {
                const { response } = err;
                tipoAlerta = 'error';
                tituloAlerta = strings.erro;
                mensagemAlerta = strings.erroGenerico;

                if (response) {
                    const { data } = response;

                    mensagemAlerta = data.message;
                    if ((response.status === HttpStatus.BAD_REQUEST || response.status === HttpStatus.INTERNAL_SERVER_ERROR) && data.errors) {
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        if (arrMensagem.length > 0) {
                            mensagemAlerta = arrMensagem.join('\n');
                        }
                    }
                }

                options = [{
                    title: strings.ok,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }];
                onClose = () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    };
                }
            })
            .finally(() => {
                setSubmitting(false);
                alerta.value = {
                    ...alerta.value,
                    type: tipoAlerta,
                    title: tituloAlerta,
                    message: mensagemAlerta,
                    open: true,
                    options: options,
                    onClose: onClose
                }
            });
    }

    return (
        <SttModal
            title={strings.registroAtendimento}
            open={true}
            maxWidth="lg"
            fullWidth={true}
            outModalClose={fecharModalRegistro}
            iconClose={fecharModalRegistro}
            children={
                <div className={classes.wrapper}>
                    {
                        initialValues.value ?
                            <Formik
                                initialValues={initialValues.value}
                                validationSchema={schema}
                                innerRef={formRef}
                                onSubmit={(dados, { setSubmitting }) => {
                                    setSubmitting(false);
                                    alerta.value = {
                                        ...alerta.value,
                                        open: true,
                                        title: strings.atencao,
                                        type: 'alert',
                                        message: strings.confirmarRegistroAtendimento,
                                        options: [{
                                            title: strings.sim,
                                            onClick: () => {
                                                enviarForm(dados, setSubmitting)
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        },
                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                setSubmitting(false);
                                                alerta.value = {
                                                    ...alerta.value,
                                                    open: false
                                                };
                                            }
                                        }],
                                        onClose: () => {
                                            setSubmitting(false);
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            };
                                        }
                                    };
                                }}
                            >
                                {
                                    ({
                                        isSubmitting,
                                        errors,
                                        submitCount,
                                        values,
                                        setFieldValue,
                                        handleSubmit,
                                    }) => {
                                        return (
                                            <form onSubmit={handleSubmit} noValidate>
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.paciente}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <Paciente />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.relatosPaciente}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <RelatoPaciente />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.cid10}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttCid10
                                                                strings={strings}
                                                                headers={getHeaders()}
                                                                formExterno={{
                                                                    cid10: values.cid10,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.ciap2}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <SttCiap2
                                                                strings={strings}
                                                                headers={getHeaders()}
                                                                formExterno={{
                                                                    ciap2: values.ciap2,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttDivider />
                                                <SttExpansionPanel
                                                    classegriditem={classes.expansionPanel}
                                                    title={strings.desfecho}
                                                    children={
                                                        <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                            <Desfecho />
                                                        </Suspense>
                                                    }
                                                />
                                                <SttLoading
                                                    open={isSubmitting}
                                                    text={strings.salvandoMensagemEspera}
                                                />
                                            </form>
                                        )
                                    }
                                }
                            </Formik>
                            : null
                    }
                </div>
            }
            footer={
                <>
                    {
                        !atendimentoEncerrado.value &&
                        <SttButton
                            variant="contained"
                            color="primary"
                            nomarginleft="true"
                            className={classes.button}
                            disabled={formRef.current?.isSubmitting}
                            onClick={() => formRef.current?.handleSubmit()}>

                            {strings.salvar}
                        </SttButton>
                    }
                    <SttButton
                        variant="outlined"
                        color="primary"
                        onClick={fecharModalRegistro}>
                        {strings.fechar}
                    </SttButton>
                </>
            }
        />
    );
};

export default ModalRegistroEvento;